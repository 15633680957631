import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import { createScroom } from "scroom";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "examples"
    }}>{`Examples`}</h1>
    <p>{`Scroom is easy to use, what you achieve is up to your imagination.`}</p>
    <br />
    <h2 {...{
      "id": "basic"
    }}>{`Basic`}</h2>
    <Playground __position={1} __code={'() => {\n  const ref = React.useRef()\n  const [colors, setColors] = React.useState([0, 0, 0])\n  React.useEffect(() => {\n    const sc = createScroom({\n      target: ref.current,\n    })\n    sc.on(\'progress\', ({ progress }) => {\n      setColors([\n        colors[0] + progress * 60,\n        colors[1] + progress * 80,\n        colors[2] + progress * 250,\n      ])\n    })\n    return () => {\n      sc.destroy()\n    }\n  }, [])\n  const background = `rgb(${colors[0]}, ${colors[1]}, ${colors[2]})`\n  return <div ref={ref} style={{ height: \'300px\', background }} />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      createScroom,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const ref = React.useRef();
        const [colors, setColors] = React.useState([0, 0, 0]);
        React.useEffect(() => {
          const sc = createScroom({
            target: ref.current
          });
          sc.on("progress", ({
            progress
          }) => {
            setColors([colors[0] + progress * 60, colors[1] + progress * 80, colors[2] + progress * 250]);
          });
          return () => {
            sc.destroy();
          };
        }, []);
        const background = `rgb(${colors[0]}, ${colors[1]}, ${colors[2]})`;
        return <div ref={ref} style={{
          height: "300px",
          background
        }} />;
      }}
    </Playground>
    <br />
    <h2 {...{
      "id": "with-sticky-position"
    }}>{`With Sticky Position`}</h2>
    <Playground __position={3} __code={'() => {\n  const ref = React.useRef()\n  const [style, setStyle] = React.useState({\n    radius: 0,\n    height: 150,\n    transform: \'\',\n  })\n  React.useEffect(() => {\n    const sc = createScroom({\n      target: ref.current,\n    })\n    sc.on(\'progress\', ({ progress }) => {\n      setStyle({\n        radius: style.radius + 300 * progress,\n        height: style.height + 150 * progress,\n        transform: `translateY(${-50 * progress}%)`,\n      })\n    })\n    return () => {\n      sc.destroy()\n    }\n  }, [])\n  return (\n    <div ref={ref} style={{ height: \'800px\' }}>\n      <div\n        style={{\n          position: \'sticky\',\n          top: \'50%\',\n          margin: \'0 auto\',\n          width: \'300px\',\n          background: \'#96E75D\',\n          height: style.height + \'px\',\n          borderRadius: style.radius + \'px\',\n          transform: style.transform,\n        }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      createScroom,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const ref = React.useRef();
        const [style, setStyle] = React.useState({
          radius: 0,
          height: 150,
          transform: ""
        });
        React.useEffect(() => {
          const sc = createScroom({
            target: ref.current
          });
          sc.on("progress", ({
            progress
          }) => {
            setStyle({
              radius: style.radius + 300 * progress,
              height: style.height + 150 * progress,
              transform: `translateY(${-50 * progress}%)`
            });
          });
          return () => {
            sc.destroy();
          };
        }, []);
        return <div ref={ref} style={{
          height: "800px"
        }}>
        <div style={{
            position: "sticky",
            top: "50%",
            margin: "0 auto",
            width: "300px",
            background: "#96E75D",
            height: style.height + "px",
            borderRadius: style.radius + "px",
            transform: style.transform
          }} />
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      